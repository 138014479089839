/* Define CSS custom properties for scrollbar colors */
:root {
  --scrollbar-start: #EF6004;
  --scrollbar-end: #0050B0;
}

html {
  scroll-behavior: smooth;
}
body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  
}


/* Style the scrollbar track with a gradient background */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
  border-radius: 6px; /* Make it rounded */
}

/* Style the scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background: rgb(0,80,176);
background: linear-gradient(0deg, rgba(0,80,176,1) 0%, rgba(239,96,4,1) 100%);
  border-radius: 6px; /* Make it rounded */
}






.wrap-balance {
  text-wrap:balance;
}

.bg-zoom {
  background-position: center;
  background-repeat: no-repeat;
  background-origin: center;
  background-size: 100%;
  transition: background-size;
  transition-duration: 0.5s;

  @media only screen and (max-width: 767px) {
    background-size: cover;
    
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    
  .bg-zoom {
    
      background-size: cover;
  }

  .pop-table-header {
    .cover {
      background-color: #0050B0;
    }
   
  }
}

.bg-zoom:hover {
  @media only screen and (min-width: 768px) {background-size: 120%;}
  
}

.huge-image-banner {
  @media only screen and (max-height: 800px){
      transform:  scale(0.8) !important
  }

  @media only screen and (max-height: 740px){
    transform:  scale(0.7) !important
}
}

.about-bg{
  @media only screen and (max-height: 800px){
    /* background-size:contain !important;
    background-position-y: 65% !important;
    color:red !important */
}
}



@layer components {

  /* .contained-rounded-button {
    @apply 
    font-medium py-2 px-6 border whitespace-nowrap
    rounded-full transition-colors duration-300 ease-in-out
    disabled:hover:bg-white disabled:hover:text-gray-400 disabled:hover:border-inherit disabled:border-inherit disabled:text-gray-400;
  } */

  h1 {
    @apply text-4xl max-xs:text-3xl
  }

  h2 {
    @apply text-3xl max-xs:text-2xl
  }

  h3 {
    @apply text-2xl max-xs:text-xl
  }
  h4 {
    @apply text-xl
  }
  h5 {
    @apply text-lg
  }
  h6 {
    @apply text-base
  }

  .text-description{
    @apply text-base
  }

  .contained-rounded-button {
    @apply 
    font-medium py-2 px-6 border whitespace-nowrap
    rounded-full transition-colors duration-300 ease-in-out
    disabled:hover:bg-white disabled:hover:text-gray-400 disabled:hover:border-inherit disabled:border-inherit disabled:text-gray-400 disabled:bg-white;
  }


  .blue-button {
    @apply 
    bg-default-blue text-white hover:bg-white hover:text-default-blue  
    border-default-blue hover:border-default-blue 
   
  }

  .orange-button {
    @apply 
    bg-default-orange text-white hover:bg-white hover:text-default-orange  
    border-default-orange hover:border-default-orange 
  }

  .btn-icon-move-right {
    @apply 
    [&_svg]:hover:translate-x-5 [&_svg]:hover:transform [&_svg]:hover:duration-500 [&_svg]:hover:transition-transform
  }

  .text-underlined-orange {
    @apply  border-b-2 border-default-orange
  }

  .content-line-break {
    line-break: after-white-space;
  }

  

}

@tailwind base;
@tailwind components;
@tailwind utilities;
